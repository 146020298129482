// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { deleteJson, getJson, postJSON } from 'components/api/request';
import { horizon, aegis } from 'components/report';
import { ApiRequest, ResponseBase, ProductsReqBase } from 'components/api/types';

/**
 * 内容类型
 */
export enum CONTENT_TYPE {
  /** 话题 */
  TOPIC = 'topic'
}

/**
 * 用户行为类型
 */
export enum USER_ACTION_TYPE {
  /** 点赞 */
  LIKE = 'like'
}


export interface PostV1UserActionDoneBatchReq extends ProductsReqBase {
  contentType: CONTENT_TYPE;
  userActionType: USER_ACTION_TYPE
  contentIds: string[] | number[]
}


export interface PostV1UserActionReq extends ProductsReqBase {
  contentType: CONTENT_TYPE;
  userActionType: USER_ACTION_TYPE
  contentId: number
}

export interface PostV1UserActionCountInitReq extends PostV1UserActionReq {
  count: number
}
/**
 * 产品-内容-用户行为 - 批量获取用户是否操作
 */
export const postV1UserActionDoneBatch: ApiRequest<
  PostV1UserActionDoneBatchReq,
  ResponseBase<any>
> = ({ productId, contentType, userActionType, contentIds }) => postJSON({
  url: `/api/v1/${productId}/${contentType}/${userActionType}/done/batch`,
  data: {
    content_ids: contentIds,
  },
});

/**
 * 产品-内容-用户行为 - 新增
 */
export const postV1UserAction: ApiRequest<
  PostV1UserActionReq,
  ResponseBase<any>
> = ({ productId, contentType, userActionType, contentId }) => postJSON({
  url: `/api/v1/${productId}/${contentType}/${contentId}/${userActionType}`,
});

/**
 * 产品-内容-用户行为 - 删除
 */
export const deleteV1UserAction: ApiRequest<
  PostV1UserActionReq,
  ResponseBase<any>
> = ({ productId, contentType, userActionType, contentId }) => deleteJson({
  url: `/api/v1/${productId}/${contentType}/${contentId}/${userActionType}`,
});


/**
 * 产品-内容-用户行为 - 新增
 */
export const postV1UserActionCountInit: ApiRequest<
  PostV1UserActionCountInitReq,
  ResponseBase<any>
> = ({ productId, contentType, userActionType, contentId, count }) => postJSON({
  url: `/api/v1/${productId}/${contentType}/${contentId}/${userActionType}/count_init`,
  data: { count },
});
