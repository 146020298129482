// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson, deleteJson, postJSON, postFormData } from 'components/api/request';
import { ApiRequest, ProductsReqBase, ResponseBase } from 'components/api/types';

enum TopicTypes {
  /** 进行中；done */
  Doning = 'doing',
  /** 已完成 */
  Done = 'done',
}
interface PostApiV2TopicsReq extends ProductsReqBase {
  /** 标题 */
  title: string;
  /** 内容 支持富文本 */
  desc?: string;
  /** 话题类型 */
  type?: TopicTypes
}

interface PostApiV2TopicsResp extends ResponseBase<{ id: number }> { }
/**
 * 产品新增话题
 */
export const postApiV2Topics: ApiRequest<
  PostApiV2TopicsReq,
  PostApiV2TopicsResp
> = ({ productId, title, desc, type }) => postJSON({
  url: `/api/v2/${productId}/topics`,
  data: {
    title,
    desc,
    type,
  },
});
