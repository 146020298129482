/**
 * 统一约束路由跳转相关方法
 */
const Route = {
  /** 跳转到某个页面 */
  push(params = '') {
    if (typeof params === 'string') {
      window.location.assign(params);
    } else if (typeof params.url === 'string') {
      window.location.assign(params.url);
    } else {
      console.error('跳转参数错误');
    }
  },
  /** 关闭当前页面，跳转到某个页面 */
  replace(params = '') {
    if (typeof params === 'string') {
      window.location.replace(params);
      return;
    } if (typeof params.url === 'string') {
      window.location.replace(params.url);
      return;
    }
    console.error('跳转参数错误');
  },
  /** 后退一个页面 */
  back() {
    window.history.back();
  },
};

export default Route;
